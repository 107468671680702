/* eslint-disable no-underscore-dangle */
import * as yup from 'yup';

import PropTypes from 'prop-types';

const registeredComponents = [];

export function ContentBlockRenderer(props) {
  const {
    data,
    context,
    preComponentRender,
  } = props;
  const {
    id,
    blocks,
  } = data;

  return blocks.map((blockItem) => {
    const componentDefinition = registeredComponents
      .find((component) => component.__typename === blockItem.__typename);

    if (!componentDefinition) {
      throw new Error(`Component of type '${blockItem.__typename}' not found.\nRegister component in "cmsconfig.js" file.`);
    }

    const { component: Component } = componentDefinition;

    return (
      <>
        {preComponentRender && preComponentRender(componentDefinition)}
        <Component
          key={`${id}_${blockItem.__typename}_${blockItem.id}`}
          blocks={blocks}
          context={context}
          data={blockItem}
          options={componentDefinition.options}
        />
      </>
    );
  });
}

ContentBlockRenderer.propTypes = {
  data: PropTypes.shape({
    blocks: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.any.isRequired,
      __typename: PropTypes.string.isRequired,
    })),
  }).isRequired,
  context: PropTypes.shape({
    page: PropTypes.object.isRequired,
  }).isRequired,
};

ContentBlockRenderer.registerComponent = (name, component, options) => {
  const componentDefinition = {
    __typename: name,
    component,
    options,
  };

  if (process.env.NODE_ENV === 'development') {
    registerComponentArgsSchema.validateSync(componentDefinition);
  }

  registeredComponents.push(componentDefinition);
};

const registerComponentArgsSchema = yup.object({
  __typename: yup.string()
    .min(3)
    .required(),
  component: yup.mixed()
    .required(),
});
