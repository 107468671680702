import {
  Box,
  Fab,
  IconButton,
} from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import { FiSettings } from 'react-icons/fi';
import { useRouter } from 'next/router';
import { trackEvent } from '@components/organisms/Tracking';
import { zIndices } from '@/theme';

const RootDiv = styled(Box)(({ theme }) => ({
  position: 'fixed',
  right: '110px',
  bottom: 82,
  zIndex: zIndices.workshopFab,
}));

export default function WorkshopFab(props) {
  const {
    data,
    iconButton,
  } = props;
  const { t } = useTranslation();
  const router = useRouter();

  const {
    sidebarUrlWorkshop,
  } = data ?? {};

  const handleClick = () => {
    trackEvent('Contact');
    trackEvent('ContactCustom', {
      channel: 'WorkshopAppointment',
      origin: 'Workshop Floating Action Bar',
    });
    router.push(sidebarUrlWorkshop ?? '#');
  };

  if (iconButton) {
    return (
      <IconButton
        aria-label="Workshop"
        onClick={handleClick}
      >
        <FiSettings
          style={{
            width: '24px',
            height: '24px',
          }}
        />
      </IconButton>
    );
  }

  return (
    <RootDiv
      sx={{
        display: {
          xs: 'none',
          md: 'block',
        },
      }}
    >
      <Fab
        aria-label="Workshop"
        color="primary"
        onClick={handleClick}
        variant="extended"
      >
        {t('components.molecules.WorkshopFab.text')}
        <FiSettings
          style={{
            width: '24px',
            height: '24px',
            marginLeft: '8px',
          }}
        />
      </Fab>
    </RootDiv>
  );
}

WorkshopFab.propTypes = {
  iconButton: PropTypes.bool,
  data: PropTypes.object,
};
