import * as yup from 'yup';

import defaultsDeep from 'lodash/defaultsDeep';
import isNil from 'lodash/isNil';

/**
 * Content element hook used to extract & validate data
 * @param {Data for the content block} data
 * @param {Data schema for validation} schema
 * @param {(Optional) Options for the hook} options
 */
export default function useContentElement(data, schema, options = {}) {
  // Arguyment validation
  if (isNil(data)) {
    throw new Error('Argument "data" must not be null or undefined.');
  }

  if (!schema.validateSync) {
    throw new Error('Argument "schema" is not of type "yup". Pleas eprovide a yup schema. https://github.com/jquense/yup');
  }

  if (process.env.NODE_ENV === 'development') {
    // Schema validation
    schema.validateSync(data);

    options = defaultsDeep(options, defaultOptions);
    optionsSchema.validateSync(options);
  }

  return {
    elementData: data,
  };
}

const optionsSchema = yup.object().shape({

});
const defaultOptions = {

};
