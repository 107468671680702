import '../polyfills';
import '../theme/global.css';
import 'klaro/dist/klaro.css';
import 'swiper/swiper-bundle.css';
import '@lib/gdpr';
import App from 'next/app';
import * as yup from 'yup';
import Head from 'next/head';
import { de } from 'yup-locales';
import { I18nextProvider } from 'react-i18next';
import PropTypes from 'prop-types';
import getConfig from 'next/config';
import Router, { useRouter } from 'next/router';
import * as Sentry from '@sentry/browser';
import { ApolloProvider, gql } from '@apollo/client';
import { ButtonElement } from '@plugins/next-cms-core';
import dynamic from 'next/dynamic';
import CssBaseline from '@mui/material/CssBaseline';
import React, { useEffect } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { AppCacheProvider } from '@mui/material-nextjs/v15-pagesRouter';
import nProgress from 'nprogress';
import ProvenExpertBar from '@components/organisms/ProvenExpertBar';
import WorkshopFab from '@components/molecules/WorkshopFab';
import i18n from '../i18n';
import theme, { hyundaiTheme, mitsubishiTheme, zIndices } from '../theme';
import Footer from '../components/organisms/Footer';
import Header from '../components/organisms/Header';
import ProvenExpertContext from '../context/ProvenExpertContext';
import WhatsappFab from '../components/molecules/WhatsappFab';
import ScrollToTopFab from '../components/molecules/ScrollToTopFab';
import FloatingActionBar from '../components/organisms/FloatingActionBar';
import { initializeApollo, useApollo } from '@/state';

yup.setLocale(de);
Router.events.on('routeChangeStart', nProgress.start);
Router.events.on('routeChangeError', nProgress.done);
Router.events.on('routeChangeComplete', nProgress.done);

const GlobalNotice = dynamic(() => import('../components/organisms/GlobalNotice'), { ssr: false });
const Tracking = dynamic(() => import('../components/organisms/Tracking'), { ssr: false });

const { publicRuntimeConfig } = getConfig();

if (publicRuntimeConfig.SENTRY_DSN) {
  // https://leerob.io/blog/configuring-sentry-for-nextjs-apps
  Sentry.init({
    enabled: !publicRuntimeConfig.isDev,
    dsn: publicRuntimeConfig.SENTRY_DSN,
  });
}

class MyApp extends App {
  static async getInitialProps({
    Component,
    ctx,
  }) {
    const deviceType = 'mobile'; // parser(ctx.req.headers['user-agent']).device.type || 'mobile';

    // On the server, initialize a new graphql client
    // on every request
    const graphQlClient = initializeApollo();
    ctx.graphQlClient = graphQlClient;

    // Default init query
    const { data } = await graphQlClient.query({
      query: DEFAULT_QUERY,
      variables: {
        path: getPathFromQuery(ctx.query, ctx.pathname),
        slug: ctx.query.slug,
        locale: ctx.locale ?? publicRuntimeConfig.DEFAULT_LOCALE,
      },
    });

    const pageProps = Component.getInitialProps
      ? await Component.getInitialProps(ctx) : {};
    // eslint-disable-next-line max-len
    const provenExpertGoogleStars = await fetch(`${publicRuntimeConfig.PUBLIC_URL}/api/provenexpert/googlestars`);

    return {
      pageProps: {
        ...pageProps,
        deviceType,
        appData: data,
        provenExpertGoogleStars: provenExpertGoogleStars.ok
          ? await provenExpertGoogleStars.json()
          : {},
        initialGraphQlState: graphQlClient.cache.extract(),
      },
    };
  }

  render() {
    const {
      Component,
      pageProps,
    } = this.props;

    return (
      <AppCacheProvider {...this.props}>
        <AppContainer Component={Component} pageProps={pageProps} />
      </AppCacheProvider>
    );
  }
}

function AppContainer(props) {
  const {
    Component,
    pageProps,
  } = props;
  const graphQlClient = useApollo(pageProps.initialGraphQlState);
  const router = useRouter();

  useEffect(() => {
    const handler = (event) => {
      setTimeout(() => {
        const hash = event.newURL.split('#')[1];

        if (hash) {
          const element = document.getElementById(hash.substring(7));

          if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
          }
        }
      }, 500);
    };

    setTimeout(() => {
      window.addEventListener('hashchange', handler);
      handler({
        newURL: window.location.href,
      });
    }, 1000);

    return () => {
      window.removeEventListener('hashchange', handler);
    };
  }, [router.asPath]);

  useEffect(() => {
    i18n.changeLanguage(router.locale);
  }, [router.locale]);

  let currentTheme = theme;
  let cssOverride = '';

  if (router.asPath) {
    if (router.asPath.startsWith('/hyundai')) {
      currentTheme = hyundaiTheme;
      cssOverride = `
      .brand_logo_stylized,
      .mitsubishi_tag,
      .mgmotor_tag,
      .fuso_tag,
      .maxus_tag,
      .tropos_tag {
        display: none !important;
      }

      ${theme.breakpoints.up('md')} {
        .hyundai_tag.brand_logo_origin {
          display: block !important;
        }
      }
      `;
    } else if (router.asPath.startsWith('/mitsubishi')) {
      currentTheme = mitsubishiTheme;
      cssOverride = `
        .brand_logo_stylized,
        .hyundai_tag,
        .mgmotor_tag,
        .fuso_tag,
        .maxus_tag,
        .tropos_tag {
          display: none !important;
        }

        ${theme.breakpoints.up('md')} {
          .mitsubishi_tag.brand_logo_origin {
            display: block !important;
          }
        }
      `;
    } else if (router.asPath.startsWith('/fuso')) {
      currentTheme = mitsubishiTheme;
      cssOverride = `
        .brand_logo_stylized,
        .hyundai_tag,
        .mitsubishi_tag,
        .mgmotor_tag,
        .maxus_tag,
        .tropos_tag {
          display: none !important;
        }

        ${theme.breakpoints.up('md')} {
          .fuso_tag.brand_logo_origin {
            display: block !important;
          }
        }
      `;
    } else if (router.asPath.startsWith('/mg-motor')) {
      currentTheme = mitsubishiTheme;
      cssOverride = `
        .brand_logo_stylized,
        .hyundai_tag,
        .mitsubishi_tag,
        .fuso_tag,
        .maxus_tag,
        .tropos_tag {
          display: none !important;
        }

        ${theme.breakpoints.up('md')} {
          .mgmotor_tag.brand_logo_origin {
            display: block !important;
          }
        }
      `;
    } else if (router.asPath.startsWith('/maxus')) {
      currentTheme = mitsubishiTheme;
      cssOverride = `
        .brand_logo_stylized,
        .hyundai_tag,
        .mitsubishi_tag,
        .mgmotor_tag,
        .fuso_tag,
        .tropos_tag {
          display: none !important;
        }

        ${theme.breakpoints.up('md')} {
          .maxus_tag.brand_logo_origin {
            display: block !important;
          }
        }
      `;
    }
  }

  let canonicalUrl = router.asPath.split('#')[0].split('?')[0];
  canonicalUrl = (router.locale !== publicRuntimeConfig.DEFAULT_LOCALE ? `/${router.locale}` : '') + canonicalUrl;
  canonicalUrl = `https://www.schimmel-automobile.de${canonicalUrl}`;

  if (pageProps.canonicalUrl) {
    canonicalUrl = pageProps.canonicalUrl;
  }

  return (
    <>
      <Head>
        <meta content="website" property="og:type" />
        <meta key="og:image" content="/images/og-image-default.png" property="og:image" />
        <link href={canonicalUrl} rel="canonical" />
        <meta content={canonicalUrl} property="og:url" />
      </Head>
      {cssOverride && (
        <Head>
          <style
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: cssOverride,
            }}
          />
        </Head>
      )}
      {router.asPath.startsWith('/hyundai') && (
        <Head>
          <link href="/fonts/hyundai/font.css" rel="stylesheet" />
        </Head>
      )}
      {router.asPath.startsWith('/mitsubishi') && (
        <Head>
          <link href="/fonts/mmc/font.css" rel="stylesheet" />
        </Head>
      )}
      {/* <GoogleTagManager
        gtmId={GTM_ID}
        gtmScriptUrl="https://analytics.schimmel-automobile.de/gtm.js"
      /> */}
      <ThemeProvider theme={currentTheme}>
        <ApolloProvider client={graphQlClient}>
          <I18nextProvider i18n={i18n}>
            <CssBaseline />
            <AppContent pageProps={pageProps}>
              <Component {...pageProps} />
            </AppContent>
            {/* <GlobalStyles /> */}
          </I18nextProvider>
        </ApolloProvider>
      </ThemeProvider>
      <style global jsx>
        {`
          * {
            scroll-behavior: smooth;
          }

          body {
            /*overflow-y: scroll;*/
            transition: background-color 250ms ease-in-out;
            color: ${currentTheme.palette.text.primary} !important;
            background-color: ${currentTheme.palette.secondary.main} !important;
          }

          .no-scroll {
            overflow-y: hidden !important;
          }

          #__next {
            min-height: 100vh;
            /*display: flex;
            flex-direction: column;*/
          }

          a {
            color: inherit;
            text-decoration: none;
          }

          a:focus, a:hover {
            text-decoration: underline;
          }

          p a {
            color: ${currentTheme.palette.primary.main};
            text-decoration: none !important;
            background-image: linear-gradient(to right, ${currentTheme.palette.primary.main} 25%, rgba(255, 255, 255, 0) 0%);
            background-position: bottom;
            background-size: 4px 1px;
            background-repeat: repeat-x;
          }

          p a:focus, p a:hover {
            color: ${currentTheme.palette.primary.dark};
            background-image: linear-gradient(to right, ${currentTheme.palette.primary.dark} 25%, ${currentTheme.palette.primary.dark} 0%);
          }

          .img-fluid {
            max-width: 100%;
            height: auto;
          }

          svg.icon {
            display: block;
          }

          h1, h2, h3, h4, h5, h6, p {
            margin-block-start: 0;
            margin-block-end: 0;
          }

          .onpage-anchor {
          }

          .brand_logo_origin {
            display: none;
          }

          #nprogress {
            pointer-events: none;
          }

          #nprogress .bar {
            background-color: #FF9900;
            height: 4px;
            left: 0;
            position: fixed;
            top: 0;
            width: 100%;
            z-index: ${zIndices.nProgress};
          }

          .small-icon img {
            max-width: 40px;
          }
        `}
      </style>
    </>
  );
}

AppContainer.propTypes = {
  Component: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  pageProps: PropTypes.object.isRequired,
};

function AppContent({
  children,
  pageProps,
}) {
  const {
    appData,
    pageHeadConfig,
    pageBottomConfig,
    provenExpertGoogleStars,
    isGlobalNoticeHidden,
  } = pageProps;
  const siteConfig = appData.siteConfig.data.attributes;
  const siteNotice = appData.siteNotice.data.attributes;
  const {
    renderBreadcrumbPath,
    renderBreadcrumbPathForSlug,
  } = appData;

  return (
    <>
      <Header
        breadcrumbs={[...renderBreadcrumbPath, ...renderBreadcrumbPathForSlug]}
        config={pageHeadConfig}
        data={appData}
      />
      <ProvenExpertContext.Provider value={provenExpertGoogleStars}>
        {children}
      </ProvenExpertContext.Provider>
      <Footer config={pageBottomConfig} data={appData} />
      <FloatingActionBar data={siteConfig} />
      <ScrollToTopFab />
      <WhatsappFab phoneNumbers={siteConfig.whatsappNumbers ?? []} />
      <WorkshopFab data={siteConfig} />
      <ProvenExpertBar />
      <GlobalNotice data={siteNotice} isGlobalNoticeHidden={isGlobalNoticeHidden} />
      <Tracking />
    </>
  );
}

AppContent.propTypes = {
  children: PropTypes.element.isRequired,
  pageProps: PropTypes.object,
};

function getPathFromQuery(query, pathname) {
  if (pathname === '/blog') {
    return '/blog';
  }
  if (!Array.isArray(query.path)) {
    return query.path ?? '/';
  }

  return query.path.join('/');
}

// const GlobalStyles = withStyles(globalStyles)(() => null);
export default MyApp;

const DEFAULT_QUERY = gql`
  query Default($path: String!, $slug: String, $locale: I18NLocaleCode!) {
    locales: i18NLocales {
      data {
        id
        attributes {
          code
          name
        }
      }
    }
    pageUrls: resolveAllLocaleUrlsOfPageByPath(path: $path, slug: $slug, locale: $locale) {
      id
      locale
      url
    }
    siteConfig {
      data {
        id
        attributes {
          version
          sidebarPhone
          sidebarUrlCarsearch
          sidebarUrlLocations
          sidebarUrlWorkshop
          whatsappNumbers {
            id
            name
            phone
          }
        }
      }
    }
    siteNotice {
      data {
        id
        attributes {
          globalNoticeButtons{
            ${ButtonElement.graphQlSchema}
          }
          globalNoticeText
          globalNoticeTitle
          isGlobalNoticeVisible
        }
      }
    }
    pageHeadConfig {
      data {
        attributes {
          isTopBarEnabled
        }
      }
    }
    pageBottomConfig {
      data {
        attributes {
          globalInfoText
        }
      }
    }
    navigationMain: renderNavigationCached(idOrSlug: "main-navigation", type: TREE, locale: $locale)
    renderBreadcrumbPath(path: $path, locale: $locale) {
      id
      url
      title
    }
    renderBreadcrumbPathForSlug(slug: $slug, locale: $locale) {
      id
      url
      title
    }
  }
`;
