import * as yup from 'yup';
import NextImage from '@components/atoms/Image2';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import useContentElement from '../../hooks/useContentElement';

const StyledImg = styled(NextImage)(({
  theme,
  isRounded,
  styles,
  isFluid,
  isAbsoluteFill,
}) => ({
  objectFit: 'cover',
  ...(isRounded && {
    borderRadius: theme.shape.borderRadius,
  }),
  ...styles,
  ...(isFluid && {
    display: 'block',
    maxWidth: '100%',
    height: 'auto',
  }),
  ...(isAbsoluteFill && {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  }),
}));

export default function MediaElement(props) {
  const {
    data,
    isMediaSvg,
    style,
    format = 'original',
    ...otherProps
  } = props;
  const { elementData } = useContentElement(
    data,
    MediaElement.dataSchema,
  );

  // Set default values
  const {
    file,
    metaAlt,
    objectPositionX,
    objectPositionY,
    scaling,
  } = elementData;

  if (!file) {
    return null;
  }

  switch (file.mime) {
    default:
      return (
        <MediaImage
          alt={otherProps.alt || metaAlt || file.data?.attributes.alternativeText || ''}
          file={file.data?.attributes}
          format={format}
          positionX={objectPositionX}
          positionY={objectPositionY}
          style={scaling && !isMediaSvg ? {
            ...style,
            width: SCALING_MAP[scaling ?? 'normal'],
          } : style}
          {...otherProps}
        />
      );
  }
}

function MediaImage(props) {
  const {
    file,
    alt,
    isSquare,
    isFluid,
    isAbsoluteFill,
    positionX,
    positionY,
    isLazy,
    style,
    format,
    ...otherProps
  } = props;
  const theFile = getImageFormat(file, format);
  const styles = style ?? {};

  if (positionX || positionY) {
    styles.objectPosition = `${positionX ?? 'center'} ${positionY ?? 'center'}`.toLowerCase();
  }

  return (
    <StyledImg
      alt={alt}
      height={theFile.height}
      isAbsoluteFill={isAbsoluteFill}
      isFluid={isFluid}
      isRounded={!isSquare && !isAbsoluteFill}
      loading={isLazy ? 'lazy' : 'eager'}
      src={theFile.url ?? ''}
      style={styles}
      width={theFile.width}
      {...otherProps}
    />
  );
}

const SCALING_MAP = {
  tiny: '25%',
  small: '50%',
  smaller: '75%',
  normal: '100%',
  larger: '125%',
  large: '150%',
  big: '175%',
};

function getImageFormat(file, format) {
  if (!format || format === 'original') {
    return file;
  }

  return file.formats[format]
    ?? file.formats.medium
    ?? file.formats.large
    ?? file;
}

MediaElement.propTypes = {
  // eslint-disable-next-line react/require-default-props
  data: PropTypes.shape({
    file: PropTypes.shape({
      data: PropTypes.shape({
        id: PropTypes.string,
        attributes: PropTypes.shape({
          formats: PropTypes.object,
          url: PropTypes.string,
        }),
      }),
    }),
    caption: PropTypes.string,
    metaAlt: PropTypes.string,
    objectPositionX: PropTypes.string,
    objectPositionY: PropTypes.string,
    scaling: PropTypes.oneOf([null, ...Object.keys(SCALING_MAP)]),
  }),
  isFluid: PropTypes.bool,
  // isSquare: PropTypes.bool,
  // isMediaSvg: PropTypes.bool,
  // isAbsoluteFill: PropTypes.bool,
};
MediaElement.defaultProps = {
  data: {
    file: null,
    caption: '',
    metaAlt: '',
    scaling: 'normal',
  },
  isFluid: true,
};
MediaElement.dataSchema = yup.object()
  .shape({
    file: yup.object()
      .shape({
        data: yup.object()
          .shape({
            id: yup.string()
              .nullable(),
            attributes: yup.object()
              .shape({
                formats: yup.object()
                  .nullable(),
                url: yup.string(),
              })
              .nullable(),
          }),
      })
      .nullable(),
    caption: yup.string()
      .nullable(),
    metaAlt: yup.string()
      .nullable(),
    objectPositionX: yup.string()
      .nullable(),
    objectPositionY: yup.string()
      .nullable(),
    scaling: yup.string()
      .oneOf([null, ...Object.keys(SCALING_MAP)])
      .nullable(),
  });

MediaElement.graphQlSchema = `
  file {
    data {
      id
      attributes {
        name
        createdAt
        alternativeText
        caption
        width
        height
        formats
        hash
        ext
        mime
        size
        url
      }
    }
  }
  caption
  metaAlt
  objectPositionX
  objectPositionY
  scaling
`;
